import {Controller} from "stimulus"
import $ from 'jquery';
import {checkSessionVar, setSessionVar} from "./sessionStorage_controller";
import {set_locale} from "./localize_controller";

import 'toastr/toastr.scss'

export default class extends Controller {

    static values = {
        url: String
    }
    connect() {
        let inPayProcess = sessionStorage.getItem('inPayProccess');

        $(document).ready(function() {
            if (inPayProcess === 'true') {
                $('#dropdown-buttons').empty();
                $('#dropdown-buttons').css('justify-content', 'center');
                $('#dropdown-buttons').append(`
                    <button type="button" id="reservation_progress" class="btn btn-outline-warning btn-sm" style="margin-right: 5px">
                    <span></span>${I18n.t('main.header.shopping_cart.process_btn')}</button>`)

            }
        });

    }
}