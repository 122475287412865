import {Controller} from "stimulus"
import $ from 'jquery';
import popper from '@popperjs/core'
import bootbox from 'bootbox/bootbox';
import {setSessionVar, checkSessionVar} from './sessionStorage_controller'
import consumer from "../channels/consumer"


//METODOS
String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim());
}

function open_room() {
    let user_id = $('#user_id');
    let room_id = $('#room_id');
    let room_message_room_id = $('#room_message_room_id');
    let room_message_reciever_id = $('#room_message_reciever_id');
    let room_messages = $('#message');
    let btn_room_send_message = $('#btn_send_message');
    let footer_action_assistant = $('.footer-asistant-line');
    let message_container = $('#message_container');

    $.ajax({
        url: location.origin + `/${sessionStorage.getItem('locale')}/assistant_online`,
        data: {},
        type: 'GET',
        beforeSend: function () {
            message_container.html(`
                      <div class="assistant-online-loader" >
                        <span class="fa fa-circle-o-notch fa-spin fa-3x"></span>
                      </div>
                    `)
        },
        success: function (data) {
            let html = '';
            if (data.success) {
                footer_action_assistant[0].classList.remove('ocultar');
                room_id[0].setAttribute('data-room-id', data.data.room.id);
                user_id[0].setAttribute('data-user-id', data.data.user.id);
                user_id[0].setAttribute('data-commercial', data.data.user.is_commercial);
                user_id[0].setAttribute('data-commercial-id', data.data.room.commercial_id);
                room_message_room_id.val(data.data.room.id);
                room_message_reciever_id.val(data.data.reciever.id);

                setSessionVar('chat_room', data.data.room.id, 'add', false).then(
                    room => {
                        setSessionVar('reciever_id', data.data.reciever.id, 'add', false).then(
                            reciever => {
                                setSessionVar('user_id', data.data.user.id, 'add', false).then();
                                setSessionVar('is_commercial', data.data.user.is_commercial, 'add', false).then();
                            }
                        )
                    }
                );

                let count_messsage = 0;
                data.data.messages.map((elem, index) => {
                    count_messsage+=1;
                    html += tpl_message(elem, data.data.user);
                })

                if(count_messsage > 0){
                    message_container.html(html);
                    message_container[0].scrollTo(0, message_container[0].scrollHeight);
                }else{
                    message_container.html(`<div class='block-message initial-message mb-3'>
                  <div class='message text-center'>
                    Bienvenido a la asistencia en linea<br>
                    ¿Cómo podemos ayudarle?
                  </div>
                </div>`);
                    message_container[0].scrollTo(0, message_container[0].scrollHeight);
                }


                connect_cable(data.data.room.id);
            } else {
                message_container.html(`
                      <div class="assistant-online-loader d-flex flex-column align-items-center justify-content-center">
                         <span class="text-center">${data.msg}</span>
                         <button class="btn-outline btn" data-action="assistantOnline#refresh">
                            <span class="fa fa-refresh fa-3x" ></span>
                        </button>
                      </div>
                    `)

                footer_action_assistant[0].classList.add('ocultar');
            }
        }
    })
}

function tpl_message(message, current_user) {
    let my_message = ''
    let theirs_message = ''

    if (current_user.id === message.user.id) {
        my_message = 'my-message';
    } else {
        theirs_message = 'theirs-message';
    }

    return `
         <div id="${message.id}" class="block-message ${my_message} mb-3">
              <div class="message ${theirs_message}"  data-from="${message.user_id}" data-to="${message.reciever_id}" >
                ${message.message}
              </div>
              <div class="info-message">
                ${message.user.username}
                ${message.created_at}
              </div>
            </div>
        `
}

function connect_cable(room_id) {
    let timeout;
    let user_id = $('#user_id');
    let room = $('#room_id');
    let room_messages = $('#message');
    let room_message_reciever_id = $('#room_message_reciever_id');
    let btn_room_send_message = $('#btn_send_message');
    let footer_action_assistant = $('.footer-asistant-line');
    let message_container = $('#message_container');
    let assistant = $('#assistant-line');
    let close_assistant = $('#close-assistant-line');
    let close_assistant_online2 = $('#close-assistant-line2')

    const channel_room = consumer.subscriptions.create({channel: "RoomChannel", room_id: room_id}, {
        connected() {
            console.log("Conectado a Action Cable");
            channel_room.send({
                type: 'connecting',
                room_id: Number(room_id),
                from: Number(user_id[0].dataset.userId),
                to: Number(room_message_reciever_id[0].value),
                status: true
            })

            close_assistant.css('opacity', '1');
            close_assistant.css('visibility', 'visible');
            close_assistant_online2.css('opacity', '0');
            //Al presionar cualquier tecla, notificar al otro usuario que se esta ecribiendo un mensaje
            room_messages.on('keydown', function (e) {
                console.log("Presionando tecla enter");
                if (e.key === "Enter") {
                    if (!room_messages[0].value.isEmpty()) {
                        btn_room_send_message.trigger('click');
                    } else {
                        e.stopPropagation();
                        e.preventDefault();

                    }

                }

                if (e.key !== "Enter" || e.key !== "Alt" || e.key !== "Shift" || e.key !== "Backspace") {
                    channel_room.send({
                        type: 'typing',
                        message: 'Escribiendo',
                        room_id: Number(room_id),
                        from: Number(user_id[0].dataset.userId),
                        to: Number(room_message_reciever_id[0].value),
                        code: e.key
                    })
                }


            })

            // Called when the subscription is ready for use on the server
            close_assistant.on('click', function () {
                channel_room.send({
                    type: 'connecting',
                    room_id: Number(room_id),
                    from: Number(user_id[0].dataset.userId),
                    to: Number(room_message_reciever_id[0].value),
                    status: false
                })
                assistant.removeClass('active');
                assistant.removeClass('minimized');
                setSessionVar('chat_room', '', 'delete', false).then(
                    room => {
                        consumer.disconnect();
                        setSessionVar('reciever_id', '', 'delete', false).then(
                            reciever => {
                                setSessionVar('user_id', '', 'delete', false).then();
                                setSessionVar('is_commercial', '', 'delete', false).then();
                            }
                        )
                    }
                );
            })
        },

        disconnected() {
            if (sessionStorage.getItem('is_commercial') === 'false') {
                let active = active_comercial(sessionStorage.getItem('reciever_id'))
            }
        },

        received(data) {
            // Called when there's incoming data on the websocket for this channel

            switch (data.type) {
                case 'message':
                    //Para que no se mesclen los mensajes de una conversacion con otra
                    room_messages[0].value = '';

                    if (parseInt(user_id[0].dataset.userId) === parseInt(data.message.user_id) || parseInt(user_id[0].dataset.userId) === parseInt(data.message.reciever_id)) {
                        let html;
                        if (parseInt(user_id[0].dataset.userId) === parseInt(data.message.user_id)) {
                            html = data.mine;
                        } else {
                            html = data.theirs;
                        }

                        message_container[0].innerHTML += html
                        message_container[0].scrollTo(0, message_container[0].scrollHeight);
                    }

                    break;
                case 'typing':
                    if (parseInt(user_id[0].dataset.userId) === parseInt(data.to)) {
                        if (data.code === "Enter" && data.code === "Alt" && data.code === "Shift" && data.code === "Backspace") {
                            chat_status.style.bottom = '0';
                        } else {
                            chat_status.style.bottom = '60px';
                        }
                        clearTimeout(timeout);
                        timeout = setTimeout(function () {
                            chat_status.style.bottom = '0';
                        }, 2000);
                    }
                    break
                case 'request_info':
                    if (parseInt(user_id[0].dataset.userId) === parseInt(data.to)) {
                        channel_room.send({
                            type: 'info',
                            room_id: Number(room_id),
                            from: Number(user_id[0].dataset.userId),
                            to: Number(room_message_reciever_id[0].value)
                        })
                    }
                    break
                case 'terminate':
                    if (parseInt(user_id[0].dataset.userId) === parseInt(data.to)) {
                        message_container[0].innerHTML += data.message
                        message_container[0].scrollTo(0, message_container[0].scrollHeight);
                        setTimeout(function () {
                            close_assistant.trigger('click');
                        }, 2000)
                    }
                    break
            }

        }
    });
}

function active_comercial(id) {
    let result;
    $.ajax({
        url: `${location.origin}/admin/commercial/${id}/active`,
        data: {
            format: 'html'
        },
        type: 'GET',
        success: function (data) {
            result = data;
        }
    });

    return result;
}

export default class extends Controller {


    initialize() {
        // variables

        let assistant = $('#assistant-line');
        let open_assistant = $('#open-asistant-line');
        let minimized_assistant_line = $('#minimized-assistant-line');
        let close_assistant_online = $('#close-assistant-line')
        close_assistant_online.css('opacity', '0');
        close_assistant_online.css('z-index', '0');
        close_assistant_online.css('visibility', 'hidden');
        let close_assistant_online2 = $('#close-assistant-line2')

        open_assistant.on('click', function () {
            assistant.addClass('active');
            open_room();

        })
        minimized_assistant_line.on('click', function () {
            assistant.toggleClass('minimized');
        })

        close_assistant_online2.on('click', function () {
            assistant.removeClass('active');
            assistant.removeClass('minimized');
        })

    }

    connect() {
        // variables
        let user_id = $('#user_id');
        let room_id = $('#room_id');
        let room_message_room_id = $('#room_message_room_id');
        let room_message_reciever_id = $('#room_message_reciever_id');
        let room_messages = $('#message');
        let btn_room_send_message = $('#btn_send_message');
        let footer_action_assistant = $('.footer-asistant-line');

        btn_room_send_message.on('click', function () {
            checkSessionVar('chat_room').then(
                chat_room_result => {
                    if (chat_room_result.success) {
                        $.ajax({
                            url: `${location.origin}/room_message/create`,
                            data: {
                                room_message: {
                                    message: room_messages[0].value,
                                    room_id: room_id[0].dataset.roomId,
                                    reciever_id: room_message_reciever_id[0].value
                                }
                            },
                            type: 'POST'
                        })
                    }
                }
            )


        })
    }

    refresh() {
        open_room();
    }

}