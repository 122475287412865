import {Controller} from "stimulus"
import $ from 'jquery';

import 'toastr/toastr.scss'

require('leaflet/dist/leaflet.css')
import L from "leaflet"
import {OpenStreetMapProvider} from 'leaflet-geosearch'

const provider = new OpenStreetMapProvider();

export default class extends Controller {

    static targets = ["container"]
    static values = {
        icon: String
    }

    offices;
    popup;

    connect() {
        this.offices = [];
        this.createMap()
        this.map.setView([23.136667, -82.358889], 12);

        let active_offices_url = this.data.get("active_offices_url")
        $.blockUI({message: false});
        $.ajax({
            url: active_offices_url,
            data:"",
            type:"GET",
            success: (res) => {
                $.unblockUI();
                $("#active_offices").empty();
                if (res !== undefined) {
                    console.log(res)
                    $.each(res.offices, (destinationName, dealers) => {
                        $("#active_offices").append(
                            `<details>
                                <summary><strong>${destinationName}</strong></summary>
                                <div id="${destinationName.toLowerCase().replaceAll(' ', '-')}-dealers" class="pl-3"></div>
                            </details>
                        `);

                        $.each(dealers, (dealerName, offices) => {
                            $(`#${destinationName.toLowerCase().replaceAll(' ', '-')}-dealers`).append(
                                `<details>
                                    <summary><strong>${dealerName}</strong></summary>
                                    <div id="${dealerName.toLowerCase().replaceAll(' ', '-')}-offices" class="pl-3"></div>
                                </details>`
                            );
                            offices.forEach(office => {
                                this.offices.push(office);

                                $(`#${dealerName.toLowerCase().replaceAll(' ', '-')}-offices`).append(
                                    `<div>
                                        <strong class="text-dark office" role="button"
                                        data-id="${office.id}"
                                        data-latitude="${office.latitude}" 
                                        data-longitude="${office.longitude}"
                                        data-name="${office.name}"
                                        data-phone="${office.phones}"
                                        data-optime="${office.open_time}"
                                        data-cltime="${office.close_time}"
                                        data-fulltime="${office.full_time}"
                                        data-address="${office.address}"
                                        >${office.name}</strong>
                                    </div>`
                                );
                            })
                        });
                    });
                }
            }
        });


        $(document).on('click', '.office', (event) => {
            // console.log(this.offices.filter((element) => element.id === id));

            let latLng = L.latLng(event.target.dataset['latitude'], event.target.dataset['longitude']);
            const id = event.target.dataset['id'];
            const office  = this.offices.find(office => String(office.id) === id);

            const name = event.target.dataset['name'];
            const phone= event.target.dataset['phone'];
            const optime= event.target.dataset['optime'];
            const cltime= event.target.dataset['cltime'];
            const address= event.target.dataset['address'];
            const products = event.target.dataset['product-types'];
            let fulltime = event.target.dataset['fulltime'];

            const officeProductType = [];
            office.product_types.forEach(product => {officeProductType.push(product.name)});
            officeProductType.sort();


            if (event.target.dataset['latitude'] == 0.0 && event.target.dataset['longitude'] == 0.0 ) {
                if (fulltime){
                    this.marker.bindPopup(`<h4>${name}</h4><br>
                                    <b><span>Contacto: </span></b>${phone}<br>
                                    <b><span>Horario: </span></b>24 Horas<br>
                                    <b><span>Dirección: </span></b>${address}<br>`).openPopup();
                } else {
                    this.marker.bindPopup(`<h4>${name}</h4><br>
                                    <b><span>Contacto: </span></b>${phone}<br>
                                    <b><span>Apertura: </span></b>${optime} &nbsp; <b><span>Cierre: </span></b>${cltime}<br>
                                    <b><span>Dirección: </span></b>${address}<br>`).openPopup();
                }

                this.map.setView([23.136667, -82.358889], 10);

            } else{
                this.marker.setLatLng(latLng);

                if(fulltime){
                    this.marker.bindPopup(`<h4>${name}</h4><br>
                                    <b><span>Contacto: </span></b>${phone}<br>
                                    <b><span>Horario: </span></b>24 Horas<br>
                                    <b><span>Dirección: </span></b>${address}<br>`).openPopup();
                }else{
                    this.marker.bindPopup(`<h4>${name}</h4><br>
                                       <b><span>Contacto: </span></b>${phone}<br>
                                       <b><span>Apertura: </span></b>${optime} &nbsp; <b><span>Cierre: </span></b>${cltime}<br>
                                       <b><span>Dirección: </span></b>${address}<br>`).openPopup();
                }

                this.map.setView([event.target.dataset['latitude'], event.target.dataset['longitude']],17);
            }

            $(".mapcard").empty();

            if(fulltime){
                $(".mapcard").append(
                    `<div class="mt-3 row">
                    <div class="col-md-6">  
                        <label class="form-label ">Nombre:</label>
                        <blockquote>${name}</blockquote>
                    </div>
                    
                    <div class="col-md-6">
                        <label class="form-label">Productos:</label>
                        <blockquote>${officeProductType.join(", ")}</blockquote>
                    </div>
                    
                    <div class="col-md-6 activetime">
                        <label class="form-label">Horario:</label>
                        <blockquote>24 Horas</blockquote>
                    </div>
                    
                    <div class="col-md-6">
                        <label class="form-label">Teléfono de Contacto:</label>
                        <blockquote>${phone}</blockquote>
                    </div>
                  
                    <div class="col-md-12">
                        <label class="form-label">Dirección:</label>
                        <blockquote>${address}</blockquote>
                    </div>
                    
                </div>`
                );
            }else{
                $(".mapcard").append(
                    `<div class="mt-3 row">
                    <div class="col-md-6">  
                        <label class="form-label ">Nombre:</label>
                        <blockquote>${name}</blockquote>
                    </div>
                    
                    <div class="col-md-6">
                        <label class="form-label">Productos:</label>
                        <blockquote>${officeProductType.join(", ")}</blockquote>
                    </div>
                    
                    <div class="col-md-6 activetime">
                        <label class="form-label">Horario:</label>
                        <blockquote>Apertura: ${optime}&nbsp; Cierre: ${cltime}</blockquote>
                    </div>
                    
                    <div class="col-md-6">
                        <label class="form-label">Teléfono de Contacto:</label>
                        <blockquote>${phone}</blockquote>
                    </div>
                  
                    <div class="col-md-12">
                        <label class="form-label">Dirección:</label>
                        <blockquote>${address}</blockquote>
                    </div>
                    
                </div>`
                );
            }

        });
    }

    createMap() {
        this.map = L.map(this.containerTarget,{
            attribution: false,
            attributionControl: false,
            dragging: false,
            interactive: false,
        })

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            zoom: 17,
            minZoom: 10,
            maxZoom: 19,
            zoomSnap: 0.25,
            touchZoom: false,
        }).addTo(this.map);


        let myIcon = L.icon({
            iconUrl: this.iconValue,
            className: 'pin-map',
            popupAnchor: [-3, -3],
            iconSize: [20, 30]
        });

        let popup = L.popup()
            .setLatLng([23.13302, -82.38304])
            .setContent("Habana, Cuba")
            .openOn(this.map);

        popup.addTo(this.map);

        this.marker = L.marker([23.13302, -82.38304],{icon: myIcon}).addTo(this.map);

    }

    disconnect() {
        this.map.remove();
    }

}